
function RealtimeTelemetryPlugin() {
    return function (openmct) {
        var listener={};
        var provider = {
            supportsSubscribe: function (domainObject) {
                return domainObject.type === 'example.telemetry';
            },
            subscribe: function (domainObject, callback) {
		
                listener[domainObject.identifier.key] = callback;
                return function unsubscribe() {
                    delete listener[domainObject.identifier.key];
                };
            }
        };

        openmct.telemetry.addProvider(provider);
    }
}
