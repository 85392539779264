function HistoricalTelemetryPlugin() {
    return function install (openmct) {
        var provider = {
            supportsRequest: function (domainObject) {
                return domainObject.type === 'example.telemetry';
            },
            request: function (domainObject, options) {
                var url = 'http://localhost:8080/proxy/?&channel=' +
                    domainObject.identifier.key +
                    '&start=' + options.start +
                    '&end=' + options.end +
		    '&size=' + options.size+
		    '&strategy='+options.strategy;
              
                return fetch(url)
                    .then(function (resp) {
                        return resp.json();
                    });
            }
        };

        openmct.telemetry.addProvider(provider);
    }
}
